<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
        <div v-if="!isLoading">
          <display-container label="Informationen">
            <div class="details">
              <data-display label="varioResource">
                <template v-if="ontResourceMissing">
                  <v-icon
                    class="mr-2"
                    color="red"
                    small
                  >
                    mdi-close
                  </v-icon>
                  <span>ONT Resource nicht vorhanden</span>
                </template>
                <template v-else>
                  <v-icon
                    class="mr-2"
                    color="green"
                    small
                  >
                    mdi-check
                  </v-icon>
                  <span>ONT Resource vorhanden</span>
                </template>
              </data-display>
              <data-display label="Nächste automatische Überprüfung">
                <template v-if="nextScheduledJob !== null">
                  {{ dueDate }}
                </template>
                <template v-else>
                  -
                </template>
              </data-display>
            </div>
          </display-container>
        </div>
        <div v-else class="my-8">
          <span>Daten werden geladen</span>
          <v-progress-linear
            color="blue accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </div>
        <div class="margin-bottom-2 align-buttons-right">
          <v-btn
            :disabled="!claimed || isLoading"
            color="primary"
            @click="completeTaskDialog = true"
          >
            Aufgabe schließen
          </v-btn>
        </div>
      </div>
      <v-dialog v-model="completeTaskDialog" width="20vw">
        <v-card>
          <v-card-text class="pt-6">
            <p>
              Soll die Aufgabe beendet werden? Dadurch läuft der Prozess normal weiter, es werden keine Daten durch SPOT geändert.
            </p>
            <v-spacer></v-spacer>
            <div class="dialog-buttons">
              <v-btn
                small
                color="secondary"
                class="align-center"
                @click="completeTaskDialog = !completeTaskDialog"
              >
                Abbrechen
              </v-btn>
              <v-btn
                small
                color="primary"
                class="align-center"
                @click="completeTask"
              >
                Ja
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import { HTTP } from '@/main/httpClient.js';
import TimeUtility from '@/util/TimeUtility.js';
import NotificationObject from '@/main/NotificationObject.js';

export default {
  name: 'ActionOntResourceMissing',
  components: {
    TaskBlocker: () => import('@/task/TaskBlocker'),
    DisplayContainer: () => import('@/components/elements/DisplayContainer'),
    DataDisplay: () => import('@/components/elements/DataDisplay.vue'),
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    taskId: null,
    isLoading: true,
    completeTaskDialog: false,
    ontResourceMissing: null,
    nextScheduledJob: null,
  }),
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
    camundaAdmin() {
      return this.$auth.user().userRoles.includes('camunda-admin');
    },
    dueDate() {
      if (this.nextScheduledJob === null) return -1;
      return TimeUtility.formatDateTime(this.nextScheduledJob.dueDate);
    }
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        if (this.taskId === null || this.taskId !== newVal.id) {
          this.taskId = newVal.id;
          setTimeout(this.loadTaskData);
        }
      }
    }
  },
  methods: {
    loadTaskData: async function () {
      this.isLoading = true;

      HTTP.get('/orderTask/ontResourceMissingDetails/' + this.data.id)
        .then(({ data }) => {
          this.nextScheduledJob = data.jobs && data.jobs.length > 0 ? data.jobs[0] : null;
          this.ontResourceMissing = data.ontResourceMissing;
        })
        .catch(({ response }) => {
          this.showToast(
            `Fehler beim laden der Prozessinformationen HTTP Status Code ${response.status}`
          );
        }).finally(() => {
          this.isLoading = false;
        });
    },
    showToast: function (message, type = 'error') {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, message)
      );
    },
    parseDate: function (date) {
      return new Date(Date.parse(date));
    },
    convertDate: function (date) {
      if (!date) return '-';
      return new Date(Date.parse(date)).toLocaleDateString('de-DE');
    },
    completeTask: function () {
      HTTP.post(`/engine/default/task/${this.data.id}/complete`, {
        ontResourceMissingTask: this.username
      })
        .then(() => {
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch(() =>
          this.showToast('Die Aufgabe konnte nicht erfolgreich abgeschlossen werden!')
        );
    }
  }
};
</script>

<style scoped>
.details {
  display: grid;
  grid-template-columns: calc(50% - var(--goe-spacing-0_5)) calc(
      50% - var(--goe-spacing-0_5)
    );
  grid-gap: var(--goe-spacing-1);
}

.dialog-buttons :first-child {
  margin-right: 5px;
}
</style>
